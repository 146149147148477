<template>
  <div>
    <img :src="logo" alt="Logo" class="logo">
    <form class="mt-4" ref="recoverPasswordform" @submit.prevent="onSubmit">
      <h2>Recover Password</h2>
      <p>Enter your email address to recover your password.</p>
      <div class="form-group">
        <label for="emailInput">Email</label>
        <div class="input-with-icon">
           
           
        <input type="email" class="form-control" id="email" name="email" v-model="user.email"
          @keydown="preventLeadingSpace($event, 'email')" placeholder="xyz@abc.com" maxlength="80" />
          <span class="icon is-small is-right">
              <i class="fas fa-envelope"></i>
            </span>
          </div>
      </div>
      <div class="d-inline-block w-100 mt-3 mb-2">
        <button :disabled="isSubmitting" type="submit" class="btn btn-primary float-left mr-2">
          {{ isSubmitting ? 'Submit' : 'Submit' }}
        </button>
        <button type="reset" class="btn btn-primary float-left">Cancel</button>
      </div>
      <div>
        <span class="dark-color"> Don't have an account? </span>
        <a href="/auth/sign-in" class="iq-waves-effect pr-4"> Sign in </a>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import constant, { toastMessage } from "@/config/constant";
import $ from 'jquery';
import 'jquery-validation';
export default {
  name: "RecoverPassword",
  props: ["email"],
  data() {
    return {
      isSubmitting: false, // Define isSubmitting in the data object
      user: {
        email: "",
      },
      message: "",
      logo: require('@/assets/images/Logo-C9lab eyeWhite.png') // Add the logo
    };
  },
  mounted() {
    this.user.email = this.$props.email;
    $(this.$refs.recoverPasswordform).validate({
      //Form Validation
      rules: {
        email: {
          required: true,
          email: true,
        },
      },
      messages: {
        email: {
          required: "Email is required",
          email: "Please enter a valid email address",
        },
      },

    });
  },
  methods: {
    //First letter space not allowed
    preventLeadingSpace(event, field) {
      if (event.key === ' ' && (event.target.selectionStart === 0 || this.user[field] === '')) {
        event.preventDefault();
      } else {
        this.user[field] = event.target.value.trim();
      }
    },
    //Mail send user email
    onSubmit() {
      if (!$(this.$refs.recoverPasswordform).valid()) return;
      this.isSubmitting = true; // Enable the submit button
      axios
        .post(
          constant.API_BASE_URL + "forgot-password/",
          {
            email: this.user.email,
            linkType: "forgot_password",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.message = response.data.message;
          this.icon = "success";
          toastMessage(this.icon, this.message);
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.message = error.response.data.message;
            this.icon = "error";
            toastMessage(this.icon, this.message);
          }
          else {
            this.message = error.response.data.email;
            this.icon = "error";
            toastMessage(this.icon, this.message);
          }


        }).finally(() => {
          this.isSubmitting = false; // Enable the submit button
        });
      this.message = "";
    },
  },

};
</script>

<style scoped>
.logo {
  display: none; /* Initially hide the logo */
}

@media screen and (max-width: 768px) {
  .logo {
    display: block; /* Show the logo on small screens */
    width: 30%; /* Adjust the width as needed */
    margin: auto; /* Center the logo */
  }
}
</style>
